import React from 'react';
import { connect } from 'react-redux';
import {Grid, Typography,Button, Avatar} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase'
import {UPDATEUSER} from '../../actions/types'
import {updateLogin} from '../../actions/loginActions';

function LoggedIn(props){
    const firebase = useFirebase();

        return (
            <Button
            variant = "outlined"
            style={{margin:"8px"}}
            onClick = {()=>{
                firebase.auth().signOut();
                props.updateLogin(UPDATEUSER,{value:undefined});
            }}
            >
                <Typography
                style={{height:"100%"}}
                variant = "h5"
                align = "center"
                color = "secondary"
                >Log Out</Typography>
                <div style={{marginLeft:"12px"}}>
                <Avatar
                    src = {props.firebase.auth.photoURL}
                    style={{maxHeight:"38px", margin : "auto"}}
                    alt = "User Profile"      
                />
                </div>
            </Button>
        );
    }


let mapStateToProps = state => ({
    firebase : state.firebase,
    login : state.login
});
let mapDispatchToProps = {
    updateLogin : updateLogin
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoggedIn);
import React from 'react';
import { connect } from 'react-redux';
import {Grid} from '@material-ui/core'
import ImagePanel from '../../components/ImagePanel'
import UploadImage from '../../components/UploadImage';


function ImagePanels(props) {
        return(
            <Grid container direction="row">
                <Grid item xs={12} sm = {5} md={4} lg={4} xl={3} style={{backgroundColor:"#262626"}}>
                    <Grid container direction = "row">
                        <Grid item>
                            <UploadImage/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={8} xl={9} style={{width:"100%"}}>
                    <Grid container direction = "row" spacing={1}>
                    <div style={{display: 'flex',
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                        width:"100%",
                        backgroundColor:"#404040"
                    }}
                    >
                        {props.panels.map((panel,i)=>(
                            <Grid key = {i} item style={{height:"100%",padding:"6px", margin:"8px",backgroundColor:"#454545"}}>
                                <ImagePanel panel={panel}/>
                            </Grid>
                        ))
                        }
                    </div>
                    </Grid>
                </Grid>
            </Grid>
        );
        
}
let mapStateToProps = state=>({
    panels : state.image_panels.panels
})
export default connect(
    mapStateToProps,
)(ImagePanels);
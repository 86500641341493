
import React, {  } from 'react';
import { connect } from 'react-redux';


function SearchItem( props ){
  if(props.hit.basepath){
    return (
        <div className="hit">
        <div className="hit-name">
          <p attribute="tour" hit={props.hit.tour}>{props.hit.tour.includes(".tour") ? props.hit.tour.substring(0,props.hit.tour.indexOf(".tour")) : props.hit.tour.substring(0,props.hit.tour.indexOf(".360ty"))}</p>
        </div>
        <div className="hit-description">
          <small attribute="basepath" hit={props.hit.basepath} >{props.hit.basepath}</small>
        </div>
        </div>
    );
  }else{
    return(
      <div className = "hidden" style={{display:"none"}} />
    )
  }
}

let mapStateToProps = state => ({
});
let mapDispatchToProps = {
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchItem);
export const UPDATEBASEPATH = "UPDATEBASEPATH";
export const UPDATEBASEPATHINPUT = "UPDATEBASEPATHINPUT";
export const BASEPATHUPDATED = "BASEPATHUPDATED";
export const UPDATEINSTANCE = "UPDATEINSTANCE";
export const UPDATENODE = "UPDATENODE";
export const UPDATEPANOREADY = "UPDATEPANOREADY";
export const UPDATECURRENTNODE = "UPDATECURRENTNODE";
export const SETMOVEABLEHOTSPOT = "SETMOVEABLEHOTSPOT";
export const CLEARMOVEABLEHOTSPOT = "CLEARMOVEABLEHOTSPOT";
export const ADDHOTSPOTPANEL = "ADDHOTSPOTPANEL";
export const REMOVEHOTSPOTPANEL = "REMOVEHOTSPOTPANEL";
export const UPDATEHOTSPOTPANEL = "UPDATEHOTSPOTPANEL";
export const UPDATELOGINPOPOVERVISIBILITY = "UPDATELOGINPOPOVERVISIBILITY";
export const UPDATELOGINPOPOVERID = "UPDATELOGINPOPOVERID";
export const UPDATEUSER = "UPDATEUSER";
export const UPDATEERRORMESSAGE = "UPDATEERRORMESSAGE";
export const ADDIMAGEPANEL = "ADDIMAGEPANEL";
export const DELETEIMAGEPANEL = "DELETEIMAGEPANEL";
export const ADDPROJECT = "ADDPROJECT";
export const DELETEPROJECT = "DELETEPROJECT";
export const RENAMEPROJECT = "RENAMEPROJECT";
export const UPDATELEVEL = "UPDATELEVEL";
export const UPDATEACTIVEPROJECT = "UPDATEACTIVEPROJECT";
export const ADDLEVEL = "ADDLEVEL";
export const SETCODEPOPOVERVISIBILITY = "SETCODEPOPOVERVISIBILITY";
export const UPDATEACTIVELEVEL = "UPDATEACTIVELEVEL";
export const ADDPROJECTS = "ADDPROJECTS";
export const ADDHOTSPOTPANELS = "ADDHOTSPOTPANELS";
export const SETHOTSPOTPANELS = "SETHOTSPOTPANELS";
export const SETPROJECTS = "SETPROJECTS";
export const RENAMELEVEL = "RENAMELEVEL";
export const DELETELEVEL = "DELETELEVEL";
export const SETIMAGEPANELS = "SETIMAGEPANELS";
export const UPDATEPROJECTBASEPATH = "UPDATEPROJECTBASEPATH";
export const UPDATEPROJECTNODE = "UPDATEPROJECTNODE";
export const UPDATEPROJECTUSEDATE = "UPDATEPROJECTUSEDATE";
export const UPDATEPROJECTENDDATE = "UPDATEPROJECTENDDATE";
export const UPDATEPROJECTSTARTDATE = "UPDATEPROJECTSTARTDATE";
import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {getFirestore} from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
import { composeWithDevTools } from 'redux-devtools-extension'

const middleware = [thunk.withExtraArgument({getFirebase,getFirestore})]
const store = createStore(
    rootReducer,
    {},
    composeWithDevTools(
        applyMiddleware(...middleware),
    ),
);

export default store;
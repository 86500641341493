import {SETHOTSPOTPANELS,ADDHOTSPOTPANEL,REMOVEHOTSPOTPANEL,UPDATEHOTSPOTPANEL,ADDHOTSPOTPANELS} from '../actions/types';

let initialState={
    panels : [
        
    ]           
}
function hotspotPanelsReducer(state = initialState, action){
    switch(action.type){
        case ADDHOTSPOTPANEL:
            return {...state,
                panels:[...state.panels,action.payload]}
        case REMOVEHOTSPOTPANEL:
            return{ // returning a copy of orignal state
                ...state, //copying the original state
                panels: state.panels.filter(panel => panel.id !== action.payload) 
                                           // returns a new filtered array
              }
        case UPDATEHOTSPOTPANEL:
            const index = state.panels.findIndex(panel => panel.id === action.payload.id); //finding index of the item
            const newArray = [...state.panels]; 
            newArray[index] = action.payload;
            return {...state,
                panels: newArray
            }
        case ADDHOTSPOTPANELS:
            return {...state,
                panels:[...state.panels.concat(action.payload)]}
        case SETHOTSPOTPANELS:
            return {
                ...state,
                panels: action.payload
            }
        default:
            return state;
    }
  }
  export default hotspotPanelsReducer
import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import {Grid, TextField, Button, ButtonGroup, Divider, Tooltip} from '@material-ui/core'
import PanoParams from '../../components/PanoParams';
import Level from '../../components/Level';
import {addLevel, setBasepath} from '../../actions/projectActions'
import {setPopoverVisibility} from '../../actions/outputCodeActions'
import firebase from '../../firebase';
import {deleteHotspotCollection,getRandomID,renderWingameCounter} from '../../helper';
import {UPDATEBASEPATHINPUT,UPDATEBASEPATH, UPDATENODE} from '../../actions/types';
import {updatePanoParam} from '../../actions/panoParamActions';
import ActiveDays from '../ActiveDays';

const db = firebase.firestore();
const userRef = db.collection("users")

function ProjectSettings(props){
    useEffect(()=>{
        renderWingameHotspots()
    },[])
    useEffect(()=>{
        setBasepath()
    },[props.projects.activeProject])

    const setBasepath = () =>{
        props.updatePanoParam(UPDATEBASEPATHINPUT, {value : props.project.basepath});
        if(props.project.basepath !== props.pano_params.basepath){
            props.updatePanoParam(UPDATEBASEPATH, {value : props.project.basepath});
            props.instance.setBasePath(props.project.basepath)
            props.instance.setStartNode(props.project.node)
            props.instance.reload()
        }else{
            props.instance.pano.openNext(`{node${props.project.node}}`)
            props.updatePanoParam(UPDATENODE, {value : parseInt(props.project.node)});
        }
    }

    const renderWingameHotspots = (level) =>{
        let hotspotIDs = props.instance.pano.getPointHotspotIds().filter(id => id.includes("Point") === false)
        hotspotIDs.forEach((hotspotID)=>{
          props.instance.pano.removeHotspot(hotspotID);
          console.log(hotspotID)
        })
        if(level){
            console.log(level)
        }else{
            props.panels.forEach((hotspot)=>{
                if(hotspot.project === props.projects.activeProject){
                    props.instance.pano.addHotspot(hotspot.id,hotspot.pan,hotspot.tilt,hotspot.div);
                    let newHS = props.instance.pano.getHotspot(hotspot.id);
                    console.log(newHS)
                    newHS.div.style.transformOrigin = "0% 0%";
                    newHS.div.style.transform = "scale("+(1/(Math.tan(props.instance.pano.getFov() * Math.PI / 180))*0.35)+")";
                    props.instance.pano.addListener("nodechange", (e) =>{
                        props.instance.pano.addHotspot(hotspot.id,hotspot.pan,hotspot.tilt,hotspot.div);
                    });
                    props.instance.pano.addListener("positionchanged",()=>{
                            props.panels.forEach((hotspot)=>{
                                if(props.instance.pano.getHotspot(hotspot.id)){
                                    let hs = props.instance.pano.getHotspot(hotspot.id);
                                    let currentPan = props.instance.pano.getPan();
                                    if(currentPan < 0){
                                        currentPan = currentPan + 360;
                                    }
                                    if(hs.pan < 0){
                                        hs.pan = hs.pan + 360;
                                    }
                                    let pan_diff = parseInt(currentPan-hs.pan);
                                    if(pan_diff < 0){
                                        pan_diff = pan_diff*-1
                                    }
                                    if(pan_diff <= 75){
                                        if(hs.div.style.visibility === "hidden"){
                                            hs.div.style.visibility = "";
                                        }
                                    }
                                    props.instance.pano.getHotspot(hotspot.id).div.style.transform = "scale("+(1/(Math.tan(props.instance.pano.getFov() * Math.PI / 180))*0.35)+")";
                                }
                            })
                        });
                    console.log(hotspot)
                    }
            })
        }
    }

    if(props.project.id === props.projects.activeProject){
        if(props.instance && props.instance.pano){
            renderWingameCounter(props.project,props.instance.pano,props.panels)
            renderWingameHotspots()
        }else{
            console.log("pano not ready")
        }
    } 
    const [saving, setSaving] = useState(false)

    const pushProjectToDb = async() =>{
        let user = firebase.auth().currentUser;
        if(user){
            await userRef.doc(user.uid).collection("Projects").doc(props.project.id).set({name:props.project.name,basepath:props.project.basepath,node:props.project.node,useDate:props.project.useDate || false,startDate:props.project.startDate || null,endDate:props.project.endDate || null});
            await userRef.doc(user.uid).update({images:props.images.map((image) => {return image.imageSrc})})
            let projectRef = userRef.doc(user.uid).collection("Projects").doc(props.project.id)
            if(props.project.levels.length !== 0){
                await Promise.all(props.project.levels.map(async(level)=>{
                    let levelFields = {buttonText: level.buttonText, label: level.label,link:level.link,name:level.name}
                    projectRef.collection("levels").doc(level.id).set(levelFields);
                    await deleteHotspotCollection(user.uid,props.project.id,level.id);
                    let levelHotspotsRaw = props.panels.filter(panel => panel.project === props.project.id && panel.level === level.id);
                    levelHotspotsRaw.forEach((levelHotspot)=>{
                        let image = levelHotspot.div.querySelector("img");
                        projectRef.collection("levels").doc(level.id).collection("hotspots").doc(levelHotspot.id).set({image:{height:image.style.height,width:image.style.width,src:levelHotspot.imageSrc},node:levelHotspot.node,pan:levelHotspot.pan,tilt:levelHotspot.tilt})
                    })
                })
                )
            }
        }
        setSaving(false)
    }
    const [inputValue, setInputValue] = useState("");
    const [projectIndex, setProjectIndex] = useState(props.projects.projects.findIndex(proj => proj.id === props.project.id))
        return (
            <Grid container direction = "column">
                <Grid item>
                    <PanoParams/>
                </Grid>
                <Grid item>
                    <ActiveDays project = {props.project}/>
                </Grid>
                <Grid item>
                    <Divider style={{backgroundColor:"#FFFFFF50",margin:"8px"}}/>
                </Grid>
                {props.projects.projects[projectIndex].levels.map((level,i)=>(
                    <Grid item key={i}>
                        <Level level={level} projectID = {props.projects.projects[projectIndex].id}/>
                    </Grid>
                ))}
                <Grid item>
                    <Grid container direction="row" alignContent="center">
                        <Grid item sm={6} style={{marginTop:"16px",marginBottom:"16px"}}>
                            <TextField
                            variant="outlined"
                            label="List Name"
                            value={inputValue}
                            onChange={(e)=>{
                                setInputValue(e.target.value)
                            }}
                            />
                        </Grid>
                        <Grid item sm={5} style={{marginLeft:"16px", alignSelf:"center"}}>
                            <Button
                            variant = "outlined"
                            color = "primary"
                            onClick={()=>{
                                let defaultList = {
                                        id : getRandomID(),
                                        name : inputValue !== "" ? inputValue : "list",
                                        link : "https://example.com",
                                        buttonText : "claim Price",
                                        label : "found:",
                                    };
                                props.addLevel({id : props.project.id,level:defaultList})
                                setInputValue("")
                            }}
                            >
                            add new List
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider style={{backgroundColor:"#FFFFFF50",margin:"8px",marginTop:0}}/>
                    </Grid>
                    <Grid item style={{marginTop:"16px",textAlign:"center"}}>
                        <ButtonGroup size="large">
                            <Button
                            onClick = {()=>{
                                props.setPopoverVisibility(true)
                            }}
                            variant = "outlined"
                            color = "secondary"
                            >
                                Get Code
                            </Button>
                            <Tooltip
                            open={firebase.auth().currentUser ? false : true}
                            title="log in to save your project"
                            arrow
                            >
                                {saving
                                ?
                                <Button
                                variant = "contained"
                                color="secondary"
                                >
                                    Saving Project
                                </Button>
                                :
                                <Button
                                onClick = {()=>{
                                    setSaving(true)
                                    pushProjectToDb()
                                }}
                                variant = "contained"
                                color="primary"
                                >
                                    Save Project
                                </Button>
                                 }
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        );
}
let mapStateToProps = state => ({
  projects : state.projects,
  panels : state.hotspot_panels.panels,
  images : state.image_panels.panels,
  instance : state.pano_params.instance,
  pano_params : state.pano_params
})

let mapDispatchToProps = {
    addLevel:addLevel,
    setPopoverVisibility: setPopoverVisibility,
    updatePanoParam:updatePanoParam
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectSettings);
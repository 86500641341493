import firebase from "firebase/app";
import "firebase/auth"; 
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

 firebase.initializeApp(firebaseConfig);
 firebase.firestore();
 firebase.functions();
 firebase.storage();
firebase.analytics();
 export default firebase;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./store";
import {main} from './themes';
import {ThemeProvider} from '@material-ui/core';
import {createFirestoreInstance, ReactReduxFirebaseProvider } from 'react-redux-firebase'
import firebase from './firebase';

const rrfConfig= {
  userProfile: 'users',
  useFirestoreForProfile: true 
};
const rrfProps = {
       firebase,
       config: rrfConfig,
       dispatch: store.dispatch,
       createFirestoreInstance
    }

ReactDOM.render(
  <Provider store={store}>    
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ThemeProvider theme={main}>
        <App />
      </ThemeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);



import firebase from './firebase';
import {SETMOVEABLEHOTSPOT} from './actions/types';
import { connect } from 'react-redux';
const db = firebase.firestore();
const userRef = db.collection("users")

export async function deleteHotspotCollection(uid,projectID,levelID) {
    
    const collectionRef = userRef.doc(uid).collection("Projects").doc(projectID).collection("levels").doc(levelID).collection("hotspots");
    const query = collectionRef.orderBy('__name__');
  
    return new Promise((resolve, reject) => {
      deleteQueryBatch(db, query, resolve).catch(reject);
    });
  }
  
  async function deleteQueryBatch(db, query, resolve) {
    const snapshot = await query.get();
  
    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }
  
    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
  
    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      deleteQueryBatch(db, query, resolve);
    });
}

export const getRandomID= ()=>{
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

  var length = 9;

  var str = '';
  for (var i = 0; i < length; i++) {
      str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}

export const renderWingameCounter = (project,pano,hotspot_panels) =>{
  if(document.getElementById("wingame_container")){
    document.getElementById("wingame_container").remove()
  }
    let wingame_container = document.createElement("div");
    wingame_container.id = "wingame_container";
    wingame_container.classList.add("wingame_container")
    wingame_container.style.zIndex= 5;
    project.levels.forEach((level)=>{
      let levelHotspots = hotspot_panels.filter(panel => panel.project === project.id && panel.level === level.id)
      wingame_container.append(createCounterContainer(level,levelHotspots.length));
    })
    pano.$.append(wingame_container)
}
const createCounterContainer = (level,hotspotsCount) =>{
  let wingame_counter_container = document.createElement("div");
  wingame_counter_container.classList.add("wingame_counter_container_"+level.id)

  let wingame_counter_label = document.createElement("p");
  wingame_counter_label.classList.add("wingame_counter_label_"+level.id)
  wingame_counter_label.innerText =level.label;

  let wingame_counter = document.createElement("p");
  wingame_counter.classList.add("wingame_counter_item_"+level.id)
  wingame_counter.innerText = hotspotsCount+"/"+hotspotsCount;

  let allfound_link_container = document.createElement("div");
  allfound_link_container.classList.add("allfound_link_container_"+level.id)

  let allfound_link_item = document.createElement("a");
  allfound_link_item.classList.add("allfound_link_item_"+level.id)
  allfound_link_item.innerText = level.buttonText;
  allfound_link_item.href = level.link
  allfound_link_item.target = "_blank"

  allfound_link_container.append(allfound_link_item)
  wingame_counter_container.append(wingame_counter_label)
  wingame_counter_container.append(wingame_counter)
  if(level.link){
    wingame_counter_container.append(allfound_link_container)
  }
  return wingame_counter_container
}


import React, { useState } from 'react';
import { connect } from 'react-redux';
import {TextField,Grid,ThemeProvider, Switch,FormControlLabel} from '@material-ui/core'
import {datePickerTheme} from '../../themes';
import {setUseDate,setEndDate,setStartDate} from '../../actions/projectActions';

function ActiveDays(props){
    console.log(props.project.useDate)
    let startDate = props.project.startDate ? new Date(props.project.startDate): new Date(Date.now());
    let startDateString = `${startDate.getFullYear()}-${startDate.getMonth()+1 < 10 ? "0"+(parseInt(startDate.getMonth())+1) : startDate.getMonth()+1}-${startDate.getDate() < 10 ? "0"+startDate.getDate():startDate.getDate()}T${startDate.getHours() < 10 ? "0"+startDate.getHours() : startDate.getHours() }:${startDate.getMinutes() < 10 ? "0"+startDate.getMinutes() : startDate.getMinutes()}`
    
    let endDate = props.project.endDate ? new Date(props.project.endDate): new Date(Date.now());
    let endDateString = `${endDate.getFullYear()}-${endDate.getMonth()+1 < 10 ? "0"+(parseInt(endDate.getMonth())+1) : endDate.getMonth()+1}-${endDate.getDate() < 10 ? "0"+endDate.getDate():endDate.getDate()}T${endDate.getHours() < 10 ? "0"+endDate.getHours() : endDate.getHours() }:${endDate.getMinutes() < 10 ? "0"+endDate.getMinutes() : endDate.getMinutes()}`
        return (
        <ThemeProvider theme={datePickerTheme}>
            <Grid container direction = "column">
                <Grid item style={{textAlign:"center"}}>
                <FormControlLabel
                    control={
                        <Switch
                        color="primary"
                        />
                    }
                    checked={props.project.useDate}
                    onChange={(e,val)=>{
                        props.setUseDate({id:props.project.id,value:e.target.value || val})
                    }}
                    
                    label="use Start-End Date"
                    color="secondary"
                    />
                </Grid>
                {props.project.useDate
                ?
                <Grid item>
                    <Grid container direction = "row" justify="center" alignContent="center">
                        <Grid item xl={6} style={{textAlign:"center"}}> 
                            <TextField
                            id="startDate"
                            label="Start-Date"
                            type="datetime-local"
                            value={startDateString}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={(e)=>{
                                let startDate = new Date(e.target.value).getTime();
                                props.setStartDate({id:props.project.id,value:startDate})
                            }}
                            />
                        </Grid>
                        <Grid item xl={6} style={{textAlign:"center"}}> 
                            <TextField
                            id="endDate"
                            label="End-Date"
                            type="datetime-local"
                            value={endDateString}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={(e)=>{
                                let endDate = new Date(e.target.value).getTime();
                                props.setEndDate({id:props.project.id,value:endDate})
                            }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                :
                <div/>
                }
            </Grid>
            
        </ThemeProvider>
        );
}
let mapStateToProps = state => ({
    pano_params: state.pano_params,
    projects : state.projects
});
let mapDispatchToProps = {
    setUseDate:setUseDate,
    setStartDate:setStartDate,
    setEndDate:setEndDate
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActiveDays);
import React from 'react';
import { connect } from 'react-redux';
import {Typography,Button, Popover, ThemeProvider} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { updateLogin } from '../../actions/loginActions';
import { UPDATELOGINPOPOVERVISIBILITY,UPDATELOGINPOPOVERID} from '../../actions/types';
import {popoverTheme} from '../../themes';
import "./index.css";
import LoginOptions from '../LoginOptions';

function NotLoggedIn(props){
   
   
 
    return (
        <ThemeProvider theme = {popoverTheme}>
            <Button
            variant = "outlined"
            style={{margin:"8px"}}
            id = "loginButton"
            onClick= { () => {
                props.updateLogin(UPDATELOGINPOPOVERID,{value:"loginButton"})
                props.updateLogin(UPDATELOGINPOPOVERVISIBILITY,{value:true})
            }}
            >
                        <Typography
                        style={{height:"100%"}}
                        variant = "h5"
                        align = "center"
                        color = "secondary"
                        >Log In</Typography>
                        <div style={{height:"100%",paddingLeft:"12px",paddingTop:"4px"}}>

                            <AccountCircleIcon
                            style={{ }}
                            color = "primary"
                            fontSize = "large"
                            />
                        </div>
            </Button>
            <Popover
              anchorEl = {document.getElementById(props.login.anchorElId)}
              open = {props.login.loginPopoverVisible}
              onClose={()=>{
                            props.updateLogin(UPDATELOGINPOPOVERVISIBILITY,{value:false})
                            }
                        }
            
              anchorOrigin={
                props.login.anchorElId === "loginButton"
                ?
                {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
                :
                {
                    vertical: 'top',
                    horizontal: 'center',
                }
            }
              transformOrigin={
                props.login.anchorElId === "loginButton"
                ?
                {
                    vertical: 'top',
                    horizontal: 'center',
                }
                :
                {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
                }
              style={{overflow:"hidden",marginLeft:0}}
            >
                <LoginOptions/>
            </Popover>
        </ThemeProvider>
    );
}

let mapStateToProps = state => ({
    firebase : state.firebase,
    login : state.login
});
let mapDispatchToProps = {
    updateLogin : updateLogin
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotLoggedIn);
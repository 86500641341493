import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid, Typography,Button,Divider} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {updateHotspotPanels} from '../../actions/hotspotPanelActions';
import {REMOVEHOTSPOTPANEL} from '../../actions/types';

function HotspotPanel(props){
        return (
            <Grid container style={{padding:"8px",backgroundColor:"#2B2B2B"}} justify = "center" alignContent="center" direction = "row" spacing={1}>
                <Grid item >
                    <img src={props.panel.imageSrc} style={{maxHeight:"56px",maxWidth:"56px"}}/>
                </Grid>
                <Grid item>
                    <Divider style={{margin:"8px",backgroundColor:"#2B2B2B",borderColor:"#2B2B2B",color:"#2B2B2B"}}/>
                </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography
                                        style={{color:"#8f8f8f"}}
                                    >
                                        node: {props.panel.node}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        style={{color:"#8f8f8f"}}
                                        >
                                        tilt: {props.panel.tilt}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        style={{color:"#8f8f8f"}}

                                    >
                                        pan: {props.panel.pan}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                <Grid item>
                    <Grid container justify = "center" alignContent = "center" direction="column">
                        <Grid item>
                            <Grid container direction = "row">
                                <Grid item sm = {10}>    
                                    <Button variant="outlined" color="secondary"
                                    onClick={()=>{
                                        if( props.instance.pano.getCurrentNode() !== props.panel.node){
                                            props.instance.pano.openNext(`{${props.panel.node}}`);
                                        }
                                        props.instance.pano.setFov(props.panel.fov);
                                        props.instance.pano.setTilt(props.panel.tilt);
                                        props.instance.pano.setPan(props.panel.pan);
                                        if(props.instance.pano.getHotspot(props.panel.id)){
                                            props.instance.pano.getHotspot(props.panel.id).div.style.visibility = "";

                                        }
                                    }}
                                    >
                                        <Typography
                                        color="secondary">
                                            jump to hotspot
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item sm = {2} style={{alignSelf:"center",textAlign:"center"}}>
                                    <DeleteForeverIcon
                                    fontSize = "large"
                                    color="primary"
                                    style={{cursor:"pointer"}}
                                    onClick={()=>{
                                        props.updateHotspotPanels(REMOVEHOTSPOTPANEL, props.panel.id);
                                        props.instance.pano.removeHotspot(props.panel.id)
                                    }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        );
}
let mapStateToProps = state =>({
    instance : state.pano_params.instance,
    projects: state.projects
});
let mapDispatchToProps = {
    updateHotspotPanels : updateHotspotPanels
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HotspotPanel);
import{SETIMAGEPANELS} from './types';
export const updateImagePanels = (type, value) => dispatch => {
    dispatch(
        {
            type: type,
            payload : value
        }
    );      
}
export const setImagePanels = (value) => dispatch => {
    dispatch(
        {
            type: SETIMAGEPANELS,
            payload : value
        }
    );      
}
import React, { useState } from 'react';
import { connect } from 'react-redux';
import SearchItem from '../SearchItem'
import {updatePanoParam} from '../../actions/panoParamActions';
import algoliasearch from 'algoliasearch'
import './index.scss'
import { Grid, TextField, Typography, InputLabel,Button } from '@material-ui/core';
import Autocomplete from "algolia-react-autocomplete";
import "algolia-react-autocomplete/build/css/index.css";
import {UPDATEBASEPATH,BASEPATHUPDATED} from '../../actions/types'
import CloseIcon from '@material-ui/icons/Close';
import {setBasepath} from '../../actions/projectActions';

const client = algoliasearch('ERXSFIJXTC', "52fe8b96fc6ea9bf76196aa1eb7bec0f")
const indexes = [
    {
      source: client.initIndex("tours"),
      displayKey: 'name',
      templates: {
        header: () => (
            <div style={{width:"100%",display:"block"}}>
                <Typography style={{display:"inline-block",padding:"12px"}} 
                variant="h6"
                > Tour-search</Typography>
                <CloseIcon style={{display:"inline-block",float:"right",padding:"6px", cursor:"pointer"}}
                onClick={(e)=>{
                    e.target.parentElement.parentElement.parentElement.style.display = "none"
                }}
                ></CloseIcon>
            </div>
        )
        ,
        suggestion: (suggestion, isSelected) => <SearchItem isSelected = {isSelected} hit = {suggestion}/>
      }
    },
]
function TourSearchInput(props){
    const handleSuggestionSelection = (selectedSuggestion) =>{
        if(selectedSuggestion.basepath){
            props.updatePanoParam(UPDATEBASEPATH, {value : selectedSuggestion.basepath});
            props.updatePanoParam(BASEPATHUPDATED, {value : true});
            props.setProjectBasepath({id:props.projects.activeProject,value:selectedSuggestion.basepath})
        }
    }   
    const [customBasepath,setCustomBasepath] = useState(false);
    const [customBasepathInput,setCustomBasepathInput] = useState("");
    const onSubmit = (e) =>{
        if(e.target.value){
            props.updatePanoParam(UPDATEBASEPATH, {value : e.target.value});
            props.updatePanoParam(BASEPATHUPDATED, {value : true});
            props.setProjectBasepath({id:props.projects.activeProject,value: e.target.value})
        }
    }
    return (
        <Grid item xs={12} sm = {12} md={12} lg={12} xl = {12}>
        <InputLabel style={{marginTop:"8px"}}>
            Basepath
        </InputLabel>
       

        <Grid container direction = "row" style={{justifyContent:"space-around"}}>
            
            <Grid style={{padding:"12px 0"}} item xs = {12} sm={8} md={9} lg={9} xl = {9} >
            {customBasepath
            ?
                <TextField
                label = "Press enter to confirm"
                variant = "outlined"
                style = {{width:"100%"}}
                value = {customBasepathInput}
                onChange = {(e)=>{
                    setCustomBasepathInput(e.target.value);
                }}
                onKeyPress = {(e)=>{
                    console.log(e)
                    if(e.key === "Enter"){
                        onSubmit(e);
                    }
                }}
                onSubmit = {(e)=>{
                    onSubmit(e);
                }}
                />
            :
                <Autocomplete indexes={indexes} onSelectionChange={selectedSuggestion => handleSuggestionSelection(selectedSuggestion)}
                    style={{width : "100%"}}>
                    <input key="input" type="search" className="aa-input-search" autoComplete="off" onClick={(e)=>{
                        let el = e.target.parentElement.parentElement.querySelector(".aa-dropdown-menus");
                        if(el){
                            el.style.display = "";
                            if(el.querySelector(".aa-dropdown-menu")){
                                el.querySelector(".aa-dropdown-menu").style.display = "";
                            }
                        }
                    }}
                    onSubmit = {(e)=>{
                        console.log(e.target.value)
                    }}
                    />
                </Autocomplete>
            }
            </Grid>
            <Grid item style={{alignSelf:"center"}}>
                <Button
                variant="outlined"
                color="primary"
                onClick={()=>{
                    setCustomBasepath(!customBasepath)
                }}
                >
                    {customBasepath ? "search" : "custom"}
                </Button>
            </Grid>
            <Grid item xs = {12}>
                <InputLabel style={{marginBottom:"8px"}}>
                    current: {props.pano_params.basepath}
                </InputLabel>
            </Grid>
      
        </Grid>
     
    </Grid>
    );
}

let mapStateToProps = state => ({
    pano_params: state.pano_params,
    projects : state.projects
});
let mapDispatchToProps = {
    updatePanoParam : updatePanoParam,
    setProjectBasepath : setBasepath
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TourSearchInput);
import {SETCODEPOPOVERVISIBILITY} from '../actions/types';

let initialState={
    open : false          
}
function OutputCodeReducer(state = initialState, action){
    switch(action.type){
        case SETCODEPOPOVERVISIBILITY:
            return {...state, open: action.payload}
   
        default:
            return state;
    }
}
export default OutputCodeReducer
import {SETMOVEABLEHOTSPOT,CLEARMOVEABLEHOTSPOT} from '../actions/types';

let initialState={
          
}
function moveableHotspotReducer(state = initialState, action){
    switch(action.type){
        case SETMOVEABLEHOTSPOT:
            console.log(action.payload)
            return({
                image: action.payload.img,
                x:action.payload.x,
                y : action.payload.y,
                id : action.payload.id
            })
        case CLEARMOVEABLEHOTSPOT:
            return {}
        default:
            return state;
    }
  }
  export default moveableHotspotReducer
import React, {useState} from 'react';
import { connect } from 'react-redux';
import {Grid, Typography} from '@material-ui/core';
import {setMoveableHotspot} from '../../actions/moveableHotspotActions';
import {SETMOVEABLEHOTSPOT,DELETEIMAGEPANEL} from '../../actions/types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {updateImagePanels} from '../../actions/imagePanelActions';
import {DragDropContainer} from 'react-drag-drop-container';

function ImagePanel(props){
    const [position, setPostion] = useState()
        return (
            <Grid container style={{paddingLeft:"6px",backgroundColor:"#363636"}} alignContent="center" direction = "column" spacing={1}>
                <Grid item style={{maxHeight:"128px",maxWidth:"128px"}}>
                   <Grid container  direction="row" justify="center">
                   <DeleteForeverIcon
                    fontSize = "large"
                    color="primary"
                    style={{cursor:"pointer"}}
                    onClick={()=>{
                        props.updateImagePanels(DELETEIMAGEPANEL,{imgSrc:props.panel.imageSrc})
                    }}
                    />
                   </Grid>
                </Grid>
                <Grid item style={{padding:"8px"}}>
                    <DragDropContainer 
                    dragClone = {true}
                    targetKey="pano"
                    dragData = {position}
                    onDrag={(dragData, currentTarget, x, y)=>{
                        setPostion({x:x,y:y})
                        console.log(position)
                    }}
                    onDragEnd={(dragData, currentTarget, x, y)=>{
                        props.instance.pano.$.querySelectorAll("div")[1].id="pano_div"
                        if(currentTarget.id === "pano_div"){
                            let img = document.getElementById(props.panel.imageSrc).cloneNode();
                          
                            img.style.width = document.getElementById(props.panel.imageSrc).getBoundingClientRect().width+"px"
                            img.style.height = document.getElementById(props.panel.imageSrc).getBoundingClientRect().height+"px"

                            props.setMoveableHotspot(SETMOVEABLEHOTSPOT,{img:img,x:x,y:y})
                        }
                    }}
                    >
                        <img id={props.panel.imageSrc} src={props.panel.imageSrc} style={{maxHeight:"64px",maxWidth:"64px"}}/>
                    </DragDropContainer>               
                </Grid>
            </Grid>
        );
}
let mapStateToProps = state =>({
    moveable_hotspot : state.moveable_hotspot,
    instance : state.pano_params.instance
});
let mapDispatchToProps = {
    setMoveableHotspot : setMoveableHotspot,
    updateImagePanels : updateImagePanels
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagePanel);
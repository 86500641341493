import React, { Component } from 'react';
import { connect } from 'react-redux';
import {UPDATEINSTANCE,BASEPATHUPDATED,UPDATEPANOREADY} from '../../actions/types';
import {updatePanoParam} from '../../actions/panoParamActions';
import {DropTarget } from 'react-drag-drop-container';

class PanoInstance extends Component {

    componentDidMount(){
        this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
        let tour_360ty = new window.Pano_360ty("360ty_container");
    
        tour_360ty.setBasePath(this.props.pano_params.basepath);
        tour_360ty.setStartNode(parseInt(this.props.pano_params.node));
        tour_360ty.setDimensions("100%","18:9");
        tour_360ty.setHorizontalAlignment("center");
        tour_360ty.setSingleImage(false);
        tour_360ty.setShareButtonVisibility(false);
        tour_360ty.setImpressumVisibility(true);
        tour_360ty.setDimensions_tablet("100%","16:9");
        tour_360ty.setHorizontalAlignment_tablet("center");
        tour_360ty.setSingleImage_tablet(false);
        tour_360ty.setShareButtonVisibility_tablet(false);
        tour_360ty.setImpressumVisibility_tablet(false);
        tour_360ty.setDimensions_mobile("100%","16:9");
        tour_360ty.setHorizontalAlignment_mobile("center");
        tour_360ty.setSingleImage_mobile(false);
        tour_360ty.setShareButtonVisibility_mobile(false);
        tour_360ty.setImpressumVisibility_mobile(false);
        tour_360ty.init();
        this.props.updatePanoParam(UPDATEINSTANCE, {value:tour_360ty});
        let pano_ready_interval = setInterval(()=>{
            if(tour_360ty.pano && tour_360ty.pano.getIsLoaded()){
                clearInterval(pano_ready_interval);
                tour_360ty.pano.stopAutorotate();
                this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                tour_360ty.pano.addListener("beforechangenode",()=>{
                    this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
                });
                tour_360ty.pano.addListener("changenode",()=>{
                    this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                    if(this.props.hotspotPanels.forEach){
                        this.props.hotspotPanels.forEach((panel) =>{
                            if(panel.node === tour_360ty.pano.getCurrentNode()){
                                tour_360ty.pano.addHotspot(panel.id,panel.pan,panel.tilt,panel.div);
                            }
                        })
                    }
                });
               
            }
        },10);
        window.tour_360ty = tour_360ty;
    }
    componentDidUpdate(){
        if(this.props.pano_params.basepath_updated){
            this.props.updatePanoParam(BASEPATHUPDATED, false);
            console.log("update");
            this.props.pano_params.instance.setBasePath(this.props.pano_params.basepath);
            this.props.pano_params.instance.reload();
            this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
            setTimeout(()=>{
                let pano_ready_interval = setInterval(()=>{
                    if(this.props.pano_params.instance.pano && this.props.pano_params.instance.pano.getIsLoaded()){
                        clearInterval(pano_ready_interval);
                        this.props.pano_params.instance.pano.stopAutorotate();
                        this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                        this.props.pano_params.instance.pano.addListener("beforechangenode",()=>{
                            this.props.updatePanoParam(UPDATEPANOREADY, {value:false});
                        });
                        this.props.pano_params.instance.pano.addListener("changenode",()=>{
                            this.props.updatePanoParam(UPDATEPANOREADY, {value:true});
                            console.log(this.props.hotspotPanels)
                            if(this.props.hotspotPanels.forEach){
                                this.props.hotspotPanels.forEach((panel) =>{
                                    if(panel.node === this.props.pano_params.instance.pano.getCurrentNode()){
                                        this.props.pano_params.instance.pano.addHotspot(panel.id,panel.pan,panel.tilt,panel.div);
                                    }
                                })
                            }
                                
                        });
                    }
                },10);
            },150)

        }
    }
    render() {
      
        return (

                <div id ="instance_container" style={{width:"100%"}}>
                    <DropTarget
                    targetKey="pano"
                    >
                        <div data-tour={this.props.pano_params.basepath} id="360ty_container" style={{width:"100%",zIndex:6}}></div>
                    </DropTarget>
                </div>
                );
    }
}
let mapStateToProps = state => ({
    pano_params : state.pano_params,
    hotspotPanels : state.hotspot_panels
});
let mapDispatchToProps = {
    updatePanoParam : updatePanoParam
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanoInstance);
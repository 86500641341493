import React from 'react';
import { connect } from 'react-redux';
import * as logo_google from '../../assets/images/logo-google.png';
import * as logo_facebook from '../../assets/images/logo-facebook.png';
import * as logo_microsoft from '../../assets/images/logo-microsoft.png';
import { useFirebase } from 'react-redux-firebase';
import { updateLogin } from '../../actions/loginActions';
import { UPDATELOGINPOPOVERVISIBILITY,UPDATEUSER, UPDATEERRORMESSAGE} from '../../actions/types';
import {Grid, Typography, Avatar} from '@material-ui/core';

function LoginOptions(props){
    const firebase = useFirebase()

    const logoStyle= {
        marginRight:"auto",
        paddingRight : "24px",
        paddingLeft : "24px"
    }
    const methodNameStyle= {
        paddingRight : "24px"
    }
    function login(providerType) {
        let data = { provider: providerType, type: 'popup' }
        return firebase.login(data).then((user)=>{
            if(user){
                props.updateLogin(UPDATELOGINPOPOVERVISIBILITY,{value:false})
                props.updateLogin(UPDATEERRORMESSAGE,{value:""})
                props.updateLogin(UPDATEUSER,{value:user})
                window.dataLayer.push({
                    event:"user_login",
                    email : user.email,
                    login_method : providerType
                });
            }
            }).catch((error)=>{
                console.log(error.message)
                props.updateLogin(UPDATEERRORMESSAGE,{value:error.message})
            })

    }
    return(
        <Grid container spacing={1} direction = "column" justify = "center" alignContent = "center" >
                    {props.login.anchorElId === "screenshotButton"
                    ?
                        <Grid item>
                            <Typography
                            variant = "h5"
                            color = "primary"
                            >
                                Log in to take screenshots
                            </Typography>
                        </Grid>
                    :
                        <div style={{display:"none"}}/>
                    }
                   
                    <Grid item

                    >
                        <Grid container direction = "row" justify = "center" alignContent = "center" 
                        className = "NotLoggedIn_loginItem"
                        style= {{cursor:"pointer"}}
                        onClick = {()=>{
                            login("google")
                        }}
                        >
                            <Grid item style={logoStyle}>
                                <Avatar
                                src={logo_google.default}
                                />
                            </Grid>
                            <Grid item style={methodNameStyle}>
                                <Typography
                                    align="center"
                                    variant="h5"
                                    color = "secondary"
                                    className="NotLoggedIn_loginItem-Header"
                                >
                                    Google Login
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction = "row" justify = "center" alignContent = "center"
                            className = "NotLoggedIn_loginItem"
                            style= {{cursor:"pointer"}}
                            onClick = {()=>{
                                login("facebook")
                            }}
                        >
                            <Grid item style={logoStyle}>
                                <Avatar
                                src={logo_facebook.default}
                                />
                            </Grid>
                            <Grid item style={methodNameStyle}>
                                <Typography
                                    align="center"
                                    variant="h5"
                                    color = "secondary"
                                    className="NotLoggedIn_loginItem-Header"
                                >
                                    Facebook Login
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction = "row" justify = "center" alignContent = "center"
                            className = "NotLoggedIn_loginItem"
                            style= {{cursor:"pointer"}}
                            onClick = {()=>{
                                login("microsoft.com")
                            }}
                        >
                            <Grid item style={logoStyle}>
                                <Avatar
                                src={logo_microsoft.default}
                                />
                            </Grid>
                            <Grid item style={methodNameStyle}>
                                <Typography
                                    align="center"
                                    variant="h5"
                                    color = "secondary"
                                    className="NotLoggedIn_loginItem-Header"
                                >
                                    Microsoft Login
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{maxWidth:"300px"}}>
                        <Typography
                        color="primary"
                        variant = "subtitle1"
                        >
                        {props.login.errorMessage}
                        </Typography>
                    </Grid>
                </Grid>
    )
}
let mapStateToProps = state => ({
    firebase : state.firebase,
    login : state.login
});
let mapDispatchToProps = {
    updateLogin : updateLogin
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginOptions);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid,Typography, Divider} from '@material-ui/core';
import Login from '../Login';
import * as logo_360ty from '../../assets/images/logo-360ty.png'

class SharedHeader extends Component {
    render() {
        return (
            <div>
                <Grid item
                    style={{cursor:"pointer",marginBottom:"8px"}}
                    onClick={()=>{
                        window.open("https://360ty.world", "_blank");
                    }}
                    >
                        <Grid container spacing={2} direction = "row" justify="center" alignContent="center">
                            <Grid item>
                                <img 
                                src={logo_360ty.default}
                                style={{maxHeight:"58px"}}
                                alt = "logo 360ty"
                                />
                            </Grid>
                            <Grid item>
                                <Grid style={{height:"100%"}}container justify="center" alignContent="center">
                                    <Typography
                                    color="secondary"
                                    variant = 'h6'
                                    style={{display:"inline-block",textDecoration:"underline"}}
                                    >
                                    visit us!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider style={{backgroundColor:"#FFFFFF85",marginBottom:"8px",marginTop:"8px"}}/>
                    <Grid item>
                        <Login/>
                    </Grid>
                    <Divider style={{backgroundColor:"#FFFFFF85",marginBottom:"8px",marginTop:"8px"}}/>
            </div>
        );
    }
}
let mapStateToProps = state => ({

});
export default connect(
    mapStateToProps,
)(SharedHeader);
import './App.css';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PanoInstance from "./container/PanoInstance"
import {Grid} from '@material-ui/core'
import ToolBar from './container/ToolBar';
import ImagePanels from './container/ImagePanels'
import HotspotMover from './components/HotspotMover';
import OutputCode from './container/OutputCode';
import firebase from './firebase';
import {updateHotspotPanels} from './actions/hotspotPanelActions';
import{updateProject,setActive,setActiveLevel} from './actions/projectActions';
import {setImagePanels} from './actions/imagePanelActions';
import {SETPROJECTS,SETHOTSPOTPANELS,SETMOVEABLEHOTSPOT} from './actions/types'
import {setMoveableHotspot} from './actions/moveableHotspotActions'

const db = firebase.firestore();
const usersRef = db.collection("users");

function App(props) {
  const getHSdiv = (id,img_src,img_height,img_width) => {
    let hs_div = document.createElement("div");
    hs_div.style.cursor = "pointer";
    let hs_img = document.createElement("img");
    hs_img.src = img_src;
    hs_img.style.height = img_height;
    hs_img.style.width = img_width;
    hs_div.append(hs_img);
    hs_div.onclick=(e)=>{
      props.setMoveableHotspot(SETMOVEABLEHOTSPOT,{img:e.target,x:e.clientX,y:e.clientY,id:id})
    }
   return hs_div;
}

     
  const authListener = () =>{
    firebase.auth().onAuthStateChanged(async(user)=>{
      let projects = [];
      let hotspots = [];
      if(user){
        let userProjectsSnapshot = await usersRef.doc(user.uid).collection("Projects").get()
        if(userProjectsSnapshot.empty){
          usersRef.doc(user.uid).collection("projects")
        }else{
          let projectDocs = userProjectsSnapshot.docs;
          await Promise.all(projectDocs.map(async(projectDoc) =>{
            return new Promise(async(resolve,reject)=>{
              let projectData = await projectDoc.data()
              projects.push({id:projectDoc.id,name:projectData.name,basepath:projectData.basepath,node:projectData.node,useDate:projectData.useDate,startDate:projectData.startDate, endDate:projectData.endDate, levels:[]});
              let projectIndex = projects.findIndex(project => project.id === projectDoc.id);
              let levelsSnapshot = await projectDoc.ref.collection("levels").get();
              if(levelsSnapshot.empty){
                console.log("no levels found")
              }else{
                levelsSnapshot.forEach(async(levelDoc)=>{
                  projects[projectIndex].levels.push({id:levelDoc.id,...levelDoc.data()})
                  
                  let levelHotspotsSnapshot = await levelDoc.ref.collection("hotspots").get();
                  if(levelHotspotsSnapshot.empty){
        
                  }else{
                    levelHotspotsSnapshot.forEach(async(hotspotDoc)=>{
                      let hotspotData = hotspotDoc.data();
                      let hs_div = getHSdiv(hotspotDoc.id,hotspotData.image.src,hotspotData.image.height,hotspotData.image.width)
                      let image = hs_div.querySelector("img");
                      hotspots.push({id:hotspotDoc.id,project:projectDoc.id,level:levelDoc.id,fov:parseFloat(hotspotData.fov),pan:parseFloat(hotspotData.pan),tilt:hotspotData.tilt,node:hotspotData.node,imageSrc:image.src, div: hs_div})
                    })
                  }
                })
              }
              resolve()
            })
            })
          )
          let images = await (await usersRef.doc(user.uid).get()).data().images
          props.setImagePanels(images)
        }
          props.updateProject(SETPROJECTS,projects)
          props.updateHotspotPanels(SETHOTSPOTPANELS,hotspots)
          props.setActiveProject(projects[projects.length-1].id)
          props.setActiveLevel(projects[projects.length-1].levels[0].id)
        }
    })
  }
  useEffect(async()=>{
    await authListener()
  },[])
  return (
    <Grid container>
   
        <Grid item xs={12} sm={6} md={6} lg={4} xl = {3} style={{backgroundColor: "#1F1F1F",overflowX:"hidden",overflowY:"auto",maxHeight:"100vh"}}>     
           <ToolBar/>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={8} xl = {9}>
          <Grid container direction="column">
              <Grid item style={{backgroundColor:"#1F1F1F"}}>
                  <ImagePanels/>
              </Grid>
              <Grid item >
                <HotspotMover/>
                <PanoInstance/>
              </Grid>
          </Grid>
        </Grid>
        < OutputCode/>
      </Grid>
  );
}
let mapStateToProps = state => ({
 projects : state.projects,
 panels : state.hotspot_panels.panels,
 instance : state.pano_params.instance
})

let mapDispatchToProps = {
  updateProject:updateProject,
  updateHotspotPanels:updateHotspotPanels,
  setActiveProject : setActive,
  setActiveLevel : setActiveLevel,
  setImagePanels : setImagePanels,
  setMoveableHotspot:setMoveableHotspot
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)

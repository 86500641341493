import {UPDATEPROJECTSTARTDATE,UPDATEPROJECTENDDATE,UPDATEPROJECTUSEDATE,UPDATEPROJECTNODE,UPDATELEVEL,UPDATEACTIVEPROJECT,ADDLEVEL,UPDATEACTIVELEVEL,DELETELEVEL,UPDATEPROJECTBASEPATH} from './types';
export const updateProject = (type, value) => dispatch => {
    dispatch(
        {
            type: type,
            payload : value
        }
    );      
}
export const updateLevel = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATELEVEL,
            payload : value
        }
    )
}
export const setActive = (id) => dispatch =>{
    dispatch(
        {
            type: UPDATEACTIVEPROJECT,
            payload: id
        }
    )
}
export const addLevel = (value) => dispatch =>{
    dispatch(
        {
            type: ADDLEVEL,
            payload: value
        }
    )
}
export const deleteLevel = (value) => dispatch =>{
    dispatch(
        {
            type: DELETELEVEL,
            payload: value
        }
    )
}
export const setActiveLevel = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEACTIVELEVEL,
            payload: value
        }
    )
}
export const setBasepath = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTBASEPATH,
            payload: value
        }
    )
}
export const setNode = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTNODE,
            payload: value
        }
    )
}
export const setUseDate = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTUSEDATE,
            payload: value
        }
    )
}
export const setStartDate = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTSTARTDATE,
            payload: value
        }
    )
}
export const setEndDate = (value) => dispatch =>{
    dispatch(
        {
            type: UPDATEPROJECTENDDATE,
            payload: value
        }
    )
}
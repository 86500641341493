import { Rnd } from 'react-rnd';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {setMoveableHotspot} from '../../actions/moveableHotspotActions';
import {CLEARMOVEABLEHOTSPOT,SETMOVEABLEHOTSPOT,ADDHOTSPOTPANEL,REMOVEHOTSPOTPANEL,UPDATEHOTSPOTPANEL} from '../../actions/types';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core';
import {updateHotspotPanels} from '../../actions/hotspotPanelActions';
import {getRandomID} from '../../helper'

function HotspotMover(props){
    const getHSdiv = (id,img_src,img_height,img_width) => {
        let hs_div = document.createElement("div");
        hs_div.style.cursor = "pointer";
        let hs_img = document.createElement("img");
        hs_img.src =img_src;
        hs_img.style.height = img_height;
        hs_img.style.width = img_width;
        hs_div.append(hs_img);
        hs_div.onclick=(e)=>{
            
            props.setMoveableHotspot(SETMOVEABLEHOTSPOT,{img:e.target,x:e.clientX,y:e.clientY,id:id})
        }
       return hs_div;
    }
    if(props.panels && props.panels.length !== 0 && props.instance && props.instance.pano){
        let hotspot = props.panels.filter(panel => panel.id === props.moveable_hotspot.id)
        if(hotspot.length !== 0){
            props.instance.pano.getHotspot(hotspot[0].id).div.style.display="none"
        }
    }
    
    var setInitState = () =>{
        if(Object.keys(props.moveable_hotspot).length !== 0 && props.instance && props.instance.pano){
            let panoDimensions = props.instance.pano.$.getBoundingClientRect();
            let tan = (1/(Math.tan( props.instance.pano.getFov() * Math.PI / 180))*0.35);
            let width = props.moveable_hotspot.image.style.width ? tan * parseFloat(props.moveable_hotspot.image.style.width.substring(0,props.moveable_hotspot.image.style.width.length-2)):tan* props.moveable_hotspot.image.getBoundingClientRect().width;
            let height = props.moveable_hotspot.image.style.height ? tan * parseFloat(props.moveable_hotspot.image.style.height.substring(0,props.moveable_hotspot.image.style.height.length-2)):tan* props.moveable_hotspot.image.getBoundingClientRect().height;
            var defaultView = {
                width : width,
                height : height,
                x : props.moveable_hotspot.x - panoDimensions.x - width/2,
                y : props.moveable_hotspot.y - panoDimensions.y - height/2,
            };
            console.log(defaultView)
        }else{
            var defaultView = {}
        }
        return defaultView;
    }
    const [state, setState] = useState(setInitState())
    useEffect(()=>{
        setState(setInitState())
    },[props.moveable_hotspot])
    if(Object.keys(state).length !== 0){
        return (
                <Rnd lockAspectRatio id = "test" style={{zIndex:20}} 
                size={{ width: state.width,  height: state.height }}
                position={{ x: state.x, y: state.y }}
                onDragStop={(e, d) => {setState({ x: d.x, y: d.y, width:state.width,height:state.height }) }}
                onResize={(e, direction, ref, delta, position) => {
                    setState({
                      width: ref.offsetWidth,
                      height: ref.offsetHeight,
                      x : state.x,
                      y: state.y
                    });
                  }}>
                    <div>
                        <CheckIcon 
                        onClick={(event)=>{
                            let mouseAngles = props.instance.pano.getPositionRawAngles(state.x, state.y );
                            let fov = props.instance.pano.getFov().toFixed(3);
                            let currentTilt = props.instance.pano.getTilt();
                            let currentPan = props.instance.pano.getPan();
                            let tilt = (currentTilt + mouseAngles.tilt).toFixed(3);
                            let pan = (currentPan - mouseAngles.pan).toFixed(3);
                            let id = props.moveable_hotspot.id || getRandomID();
                            let hs_div =  getHSdiv(id,props.moveable_hotspot.image.src,(((Math.tan( props.instance.pano.getFov() * Math.PI / 180))*state.height *(1/0.35) ))+"px",(((Math.tan( props.instance.pano.getFov() * Math.PI / 180))*state.width *(1/0.35)))+"px");
                            let hs = {
                                id : id,
                                tilt : tilt,
                                pan : pan,
                                div : hs_div
                            }
                            if(props.moveable_hotspot.id){
                                props.updateHotspotPanels(UPDATEHOTSPOTPANEL,{fov:fov,imageSrc:props.moveable_hotspot.image.src,node:props.instance.pano.getCurrentNode(),id:props.moveable_hotspot.id,project:props.projects.activeProject, level:props.projects.activeLevel,...hs})
                            }else{
                                props.updateHotspotPanels(ADDHOTSPOTPANEL,{project:props.projects.activeProject, level:props.projects.activeLevel ,fov:fov,imageSrc:props.moveable_hotspot.image.src,node:props.instance.pano.getCurrentNode(),...hs})
                            }
                            props.setMoveableHotspot(CLEARMOVEABLEHOTSPOT)
                            setState({})
                        }}
                        onTouchEnd={(event)=>{
                            let mouseAngles = props.instance.pano.getPositionRawAngles(state.x, state.y );
                            let fov = props.instance.pano.getFov().toFixed(3);
                            let currentTilt = props.instance.pano.getTilt();
                            let currentPan = props.instance.pano.getPan();
                            let tilt = (currentTilt + mouseAngles.tilt).toFixed(3);
                            let pan = (currentPan - mouseAngles.pan).toFixed(3);
                            let id = props.moveable_hotspot.id || getRandomID();
                            let hs_div =  getHSdiv(id,props.moveable_hotspot.image.src,(((Math.tan( props.instance.pano.getFov() * Math.PI / 180))*state.height *(1/0.35) ))+"px",(((Math.tan( props.instance.pano.getFov() * Math.PI / 180))*state.width *(1/0.35)))+"px");
                            let hs = {
                                id : id,
                                tilt : tilt,
                                pan : pan,
                                div : hs_div
                            }
                            if(props.moveable_hotspot.id){
                                props.updateHotspotPanels(UPDATEHOTSPOTPANEL,{fov:fov,imageSrc:props.moveable_hotspot.image.src,node:props.instance.pano.getCurrentNode(),id:props.moveable_hotspot.id,project:props.projects.activeProject, level:props.projects.activeLevel,...hs})
                            }else{
                                props.updateHotspotPanels(ADDHOTSPOTPANEL,{project:props.projects.activeProject, level:props.projects.activeLevel ,fov:fov,imageSrc:props.moveable_hotspot.image.src,node:props.instance.pano.getCurrentNode(),...hs})
                            }
                            props.setMoveableHotspot(CLEARMOVEABLEHOTSPOT)
                            setState({})
                        }}
                        style={{position:"absolute",left:0,minHeight:"10px",minWidth:"10px",color:"#03fc1c",cursor:"pointer"}}/>
                    
                        <ClearIcon style={{position:"absolute",right:0,minHeight:"10px",minWidth:"10px", color:"#fc0303",cursor:"pointer"}}
                        onClick={()=>{
                            props.setMoveableHotspot(CLEARMOVEABLEHOTSPOT)
                            if(props.moveable_hotspot.id){
                                let hotspot = props.panels.filter(panel => panel.id === props.moveable_hotspot.id)
                                if(hotspot.length !== 0){
                                    props.instance.pano.getHotspot(hotspot[0].id).div.style.display=""
                                }                            
                            }
                            setState({})
                        }}
                        onTouchEnd={()=>{
                            props.setMoveableHotspot(CLEARMOVEABLEHOTSPOT)
                            if(props.moveable_hotspot.id){
                                let hotspot = props.panels.filter(panel => panel.id === props.moveable_hotspot.id)
                                if(hotspot.length !== 0){
                                    props.instance.pano.getHotspot(hotspot[0].id).div.style.display=""
                                }                            
                            }
                            setState({})
                        }}
                        />
                            <img src={props.moveable_hotspot.image.src} style={{maxHeight:state.height,maxWidth:state.width,width:"100%",height:"100%",zIndex:20}}/>
                        </div>
                </Rnd>
        )
}else{
    return (
        <div style={{display:"none"}}/>
    )
}    
}


const mapStateToProps = (state) => ({
    moveable_hotspot : state.moveable_hotspot,
    instance : state.pano_params.instance,
    projects : state.projects,
    panels : state.hotspot_panels.panels
})

const mapDispatchToProps = {
    setMoveableHotspot : setMoveableHotspot,
    updateHotspotPanels : updateHotspotPanels
}

export default connect(mapStateToProps, mapDispatchToProps)(HotspotMover)

import {ADDIMAGEPANEL,DELETEIMAGEPANEL,SETIMAGEPANELS} from '../actions/types';

let initialState={
    panels : [
      
    ]           
}
function imagePanelsReducer(state = initialState, action){
    switch(action.type){
        case ADDIMAGEPANEL:
        return {
            ...state,
            panels: [...state.panels,{imageSrc:action.payload.imgSrc}]
        }

        case DELETEIMAGEPANEL:
            let newState = {...state};
            let newPanels = newState.panels.filter(panel => panel.imageSrc !== action.payload.imgSrc);
            return {...state,panels: newPanels}
        case SETIMAGEPANELS:
            let newPanels2 = action.payload.map((imageSrc) =>{return {imageSrc:imageSrc}})
            return {
                ...state,
                panels: newPanels2
            }
        default:
            return state;
    }
  }
  export default imagePanelsReducer
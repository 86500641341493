import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid} from '@material-ui/core';
import "firebase/auth";
import NotLoggedIn from '../NotLoggedIn';
import LoggedIn from '../LoggedIn';

class Login extends Component {
    render() {
        return (
            <Grid container style={{height:"100%"}} justify="center" alignContent="center">
                {this.props.firebase.auth.isEmpty ? <NotLoggedIn/> : <LoggedIn/>}
            </Grid>
        );
    }
}
let mapStateToProps = state => ({
    firebase : state.firebase
});

export default connect(
    mapStateToProps,
)(Login);
import React from 'react';
import { connect } from 'react-redux';
import {Grid} from '@material-ui/core'
import HotspotPanel from '../../components/HotspotPanel'

function HotspotPanels(props) {
        return(
            <Grid container direction = "column" spacing={1} style={{marginTop:"16px"}}>
            <div style={{display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
                width:"100%",
                backgroundColor:"#404040"
            }}
            >
                
                {props.panels.map((panel,i)=>(
                    panel.project === props.projects.activeProject && panel.level === props.projects.activeLevel ?
                            <Grid key = {i} item style={{width:"100%",backgroundColor:"#454545",paddingTop:"8px"}}>
                                { props.instance && props.instance.pano && props.instance.pano.getHotspot(panel.id)
                                        ?
                                        props.instance.pano.getHotspot(panel.id).div.style.visibility = ""
                                        :
                                        console.log("pano not ready")
                                        }
                                <HotspotPanel panel={panel}/>
                            </Grid>
                            :
                            <div style={{display:"none"}} key={i}>
                                {
                                   props.instance && props.instance.pano && props.instance.pano.getHotspot(panel.id)?
                                        props.instance.pano.getHotspot(panel.id).div.style.visibility = "hidden"
                                        :
                                        console.log("pano not ready")
                                }
                            </div>
                ))
                }
            </div>
            </Grid>
        );
        
}
let mapStateToProps = state=>({
    panels : state.hotspot_panels.panels,
    projects : state.projects,
    instance : state.pano_params.instance
})
export default connect(
    mapStateToProps,
)(HotspotPanels);
import React, { useState } from "react";
import { connect } from 'react-redux';
import {Button, Divider, Grid, TextField, ThemeProvider, Tooltip} from '@material-ui/core';
import {updateImagePanels} from '../../actions/imagePanelActions';
import { ADDIMAGEPANEL } from "../../actions/types";
import firebase from '../../firebase';
import {uploadTheme} from '../../themes'
import './index.css';

function UploadImage(props) {
    const [state,setState] = useState({selectedFile:null});
    const [inputValue,setInputValue] = useState("");
    let onFileChange = event => {
    
        setState({ selectedFile: event.target.files[0] });
        
      };
    let onFileUpload = () => {

        let imageRef = firebase.app().storage("gs://wingame-app-360ty.appspot.com").ref().child(state.selectedFile.name);
        imageRef.put(state.selectedFile).then((snapshot)=>{
            snapshot.ref.getDownloadURL().then((downloadURL)=>{
                props.updateImagePanels(ADDIMAGEPANEL,{imgSrc:downloadURL})
                setState({selectedFile:null});
            })
        })
    }

        return (
            <ThemeProvider theme={uploadTheme}>
                <Tooltip
                open={props.projects.projects.length === 0 ? true : false}
                title="create a project before uploading images"
                arrow
                >
                <Grid container style={{margin:"8px"}} direction="column" justify="center" alignContent="center">
                    <Grid item>
                        <Grid container direction="row" justify="center" alignContent="center">
                            <Grid item  md={8} lg={8}>
                                <TextField
                                variant="outlined"
                                label="Add from URL"
                                color = "primary"
                                value={inputValue}
                                onChange={(e,val)=>{
                                    setInputValue(val || e.target.value);
                                }}
                                />
                            </Grid>
                            <Grid item md={3} lg={3} style={{alignSelf:"center",marginLeft:"8px"}}>
                                <Button
                                variant="outlined"
                                color="secondary"
                                onClick = {()=>{
                                    props.updateImagePanels(ADDIMAGEPANEL,{imgSrc:inputValue})
                                }}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider style={{margin:"8px",backgroundColor:"#FFFFFF30"}} />
                    </Grid>
                    <Grid item>
                        <Grid container justify="center" alignContent="center">
                            <Grid item>
                                <Grid container spacing={1} direction="column">
                                    <Grid item>
                                        <input className = "UploadImage_file-input"type="file" accept="image/*" onChange={onFileChange}
                                        />

                                    </Grid>
                                    <Grid item stlye={{marginTop:"8px"}}>
                                        {state.selectedFile ?
                                        <Button 
                                        color="secondary"
                                        variant = "outlined"
                                        onClick={onFileUpload}>
                                        Upload!
                                        </Button>
                                        :
                                        <div/>
                                        }
                                                    
                                    </Grid>
                                </Grid>
                            </Grid>
                        

                        </Grid>
                    
                    </Grid>
                </Grid>
                </Tooltip>
            </ThemeProvider>
        );
}

let mapStateToProps = (state) => ({
    projects : state.projects
})

let mapDispatchToProps = {
    updateImagePanels : updateImagePanels
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadImage);
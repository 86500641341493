import {UPDATEPROJECTENDDATE,UPDATEPROJECTSTARTDATE,UPDATEPROJECTUSEDATE,UPDATEPROJECTBASEPATH,RENAMELEVEL,ADDPROJECTS,ADDPROJECT,DELETEPROJECT,RENAMEPROJECT,UPDATELEVEL,UPDATEACTIVEPROJECT,ADDLEVEL,UPDATEACTIVELEVEL, SETPROJECTS, DELETELEVEL, UPDATEPROJECTNODE} from '../actions/types';

let initialState={
    activeProject: "",
    activeLevel: "",
    projects:
        [
        ]          
}
function ProjectReducer(state = initialState, action){
    switch(action.type){
        case ADDPROJECT:
        return {
            ...state,
            projects: [...state.projects,action.payload.project]
        }
        case DELETEPROJECT:
            return {...state,projects:  state.projects.filter(project => project.id !== action.payload.id)}

        case RENAMEPROJECT:
            let newProjects2 = [...state.projects];
            newProjects2.forEach((project) => {if(project.id === action.payload.id){project.name = action.payload.newName}})
            return {...state,projects: newProjects2}
        case UPDATELEVEL:
            let ProjectIndex = state.projects.findIndex(project => project.id === action.payload.id);
            let LevelIndex = state.projects[ProjectIndex].levels.findIndex(level => level.id === action.payload.newLevel.id);
            let newProjects = [...state.projects];
            newProjects[ProjectIndex].levels[LevelIndex] = action.payload.newLevel
            return{...state, projects : newProjects}
        case UPDATEACTIVEPROJECT:
            return {...state, activeProject:action.payload}

        case UPDATEACTIVELEVEL:
            return {...state, activeLevel:action.payload}
        case ADDLEVEL:
            let ProjectIndex4 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects4 = [...state.projects];
            newProjects4[ProjectIndex4].levels.push(action.payload.level)
            console.log(newProjects4)
            return {...state,projects:newProjects4}
        case ADDPROJECTS:
            return {
                ...state,
                projects: [...state.projects.concat(action.payload)]
            }
        case SETPROJECTS:
            return{
                ...state,
                projects: action.payload
            }
        case RENAMELEVEL:
            let ProjectIndex5 = state.projects.findIndex(project => project.id === action.payload.projectID);
            let newProjects5 = [...state.projects];
            let newProject5 = newProjects5[ProjectIndex5];
            let levelIndex5 = newProject5.levels.findIndex(level => level.id === action.payload.levelID);
            newProject5.levels[levelIndex5].name = action.payload.newName;

        return{
            ...state,
            projects:newProjects5
            }
        case DELETELEVEL:
            let ProjectIndex6 = state.projects.findIndex(project => project.id === action.payload.projectID);
            let newProjects6 = [...state.projects];
            newProjects6[ProjectIndex6].levels= newProjects6[ProjectIndex6].levels.filter(level => level.id !== action.payload.levelID)
            return{
                ...state,
                projects:newProjects6
            }
        case UPDATEPROJECTBASEPATH:
            let ProjectIndex7 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects7 = [...state.projects];
            newProjects7[ProjectIndex7].basepath = action.payload.value
            return{
                ...state,
                projects:newProjects7
            }
        case UPDATEPROJECTNODE:
            let ProjectIndex8 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects8 = [...state.projects];
            newProjects8[ProjectIndex8].node = action.payload.value
            return{
                ...state,
                projects:newProjects8
            }
        case UPDATEPROJECTUSEDATE:
            let ProjectIndex9 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects9 = [...state.projects];
            newProjects9[ProjectIndex9].useDate = action.payload.value
            return{
                ...state,
                projects:newProjects9
            }
        case UPDATEPROJECTSTARTDATE:
            let ProjectIndex10 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects10 = [...state.projects];
            newProjects10[ProjectIndex10].startDate = action.payload.value
            return{
                ...state,
                projects:newProjects10
            }
        case UPDATEPROJECTENDDATE:
            let ProjectIndex11 = state.projects.findIndex(project => project.id === action.payload.id);
            let newProjects11 = [...state.projects];
            newProjects11[ProjectIndex11].endDate = action.payload.value
            return{
                ...state,
                projects:newProjects11
            }
        default:
            return state;
    }
}
export default ProjectReducer
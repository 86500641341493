import React,{useState} from 'react';
import { connect } from 'react-redux';
import { Paper,Grid,TextField, Typography,TextareaAutosize,ThemeProvider,Popover,Button } from '@material-ui/core';
import {updateLevel,setActiveLevel,updateProject,deleteLevel} from '../../actions/projectActions';
import HotspotPanels from '../../container/HotspotPanels'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {RENAMELEVEL} from '../../actions/types';
import {popoverTheme} from '../../themes';
import firebase from '../../firebase';
const db = firebase.firestore();
const usersRef = db.collection("users")


function Level(props) {
 const [hotspotsOpen, setHotspotsOpen] = useState(true);
 const [editing, setEditing] = useState(false);
 const [nameInput, setNameInput] = useState(props.level.name);
 const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);

 let projectIndex = props.projects.projects.findIndex(project => project.id === props.projectID);
    return (
        <Paper style={{backgroundColor:"#1c1c1c",padding:"8px",marginTop:"8px",border:"1px solid #FFFFFF50"}}>
            <Grid container direction = "column">
                <Grid item style={{cursor:"pointer"}} onClick = {()=>{
                    props.setActiveLevel(props.level.id)
                }}>
                    <Grid container direction = "column" justify="center">
                        <Grid item>
                                {editing === true ?
                                <div>
                                <TextareaAutosize 
                                onKeyPress={(e)=>{
                                    if(e.key === "Enter"){
                                        props.updateProject(RENAMELEVEL,{projectID: props.projects.projects[projectIndex].id,levelID:props.level.id,newName:nameInput})
                                        setEditing(false)
                                    }
                                }} 
                                value = {nameInput} 
                                onChange={(e)=>{
                                    setNameInput(e.target.value)
                                }}></TextareaAutosize>
                                <CheckIcon style={{ color:"#03fc1c"}} 
                                onClick={()=>{
                                    props.updateProject(RENAMELEVEL,{projectID: props.projects.projects[projectIndex].id,levelID:props.level.id,newName:nameInput})                                    
                                    setEditing(false)
                                }}/>
                                <ClearIcon style={{ color:"#fc0303"}} onClick={()=>{setEditing(false);setNameInput(props.level.name)}}/>
                            </div>
                            :
                            <Typography
                            variant = "h5"
                            style={props.projects.activeLevel === props.level.id && props.projects.activeProject ===  props.projects.projects[projectIndex].id ? {color:"#FFFFFF"} : {color:"#FFFFFF50"}}
                            >{props.level.name}
                            <div style={{float:"right"}}>
                                {props.projects.activeLevel === props.level.id && props.projects.activeProject ===  props.projects.projects[projectIndex].id
                                ?
                                <ArrowDropDownIcon style={{marginRight:"8px"}} color="secondary" fontSize="large"/>
                                :
                                <ArrowLeftIcon style={{marginRight:"8px"}} color="secondary" fontSize="large"/>
                                }
                            <EditIcon style={{marginRight:"8px"}} color="secondary" fontSize="large"
                                onClick = {()=>{
                                    setEditing(true)
                                }}/>
                            
                                
                                <DeleteForeverIcon id= {`level_deleteIcon-${props.level.id}`} style={{marginRight:"8px"}} color="primary" fontSize="large"
                                onClick={()=>{setDeletePopoverOpen(true)}}
                                />
                            </div>
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <ThemeProvider theme={popoverTheme}>
                            <Popover
                            open={deletePopoverOpen}
                            anchorEl={document.getElementById(`level_deleteIcon-${props.level.id}`)}
                            onClose={()=>{
                                setDeletePopoverOpen(false)
                            }}
                             anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                                <Grid container direction = "column">
                                    <Grid item>
                                        <Typography
                                        color="primary"
                                        variant="subtitle1"
                                        >
                                            Are you sure you want to delete the list {props.level.name}?
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                        color="primary"
                                        variant="subtitle1"
                                        >
                                            This is irreversible
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{marginTop:"16px"}}>
                                        <Grid container direction="row">
                                            <Grid item sm={6}  style={{textAlign:"center"}}>
                                                <Button
                                                style={{width:"90%"}}
                                                color = "primary"
                                                variant="contained"
                                                onClick={()=>{
                                                    document.getElementById("wingame_counter_container_"+props.level.id).remove()
                                                    setDeletePopoverOpen(false);
                                                    props.deleteLevel({projectID:props.projects.projects[projectIndex].id,levelID:props.level.id})
                                                    if(firebase.auth().currentUser){
                                                        let user = firebase.auth().currentUser
                                                        usersRef.doc(user.uid).collection("Projects").doc(props.projects.projects[projectIndex].id).get()
                                                        .then((snapshot) =>{
                                                            if(snapshot.exists){
                                                                snapshot.ref.collection("levels").doc(props.level.id).get().then((levelSnapshot)=>{
                                                                    if(levelSnapshot.exists){
                                                                        levelSnapshot.ref.delete({ recursive: true, yes: true})
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }
                                                }}
                                                >Delete</Button>
                                            </Grid>
                                            <Grid item sm={6} style={{textAlign:"center"}}>
                                                <Button
                                                style={{width:"90%"}}
                                                variant="contained"
                                                color = "secondary"
                                                onClick={()=>{
                                                    setDeletePopoverOpen(false)
                                                }}
                                                >Close</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Popover>
                            </ThemeProvider>
                {props.projects.activeLevel === props.level.id && props.projects.activeProject ===  props.projects.projects[projectIndex].id
                ? 
                <div>
                <Grid item style={{marginTop:"8px"}}>
                    <TextField
                    label="link"
                    variant="outlined"
                    value = {props.level.link || ""}
                    style={{width:"100%"}}
                    onChange = {(e)=>{
                        let newLevel = {...props.level,link:e.target.value}
                        props.updateLevel({id:props.projectID,newLevel:newLevel})
                    }}
                    />
                </Grid>
                <Grid item style={{marginTop:"8px"}}>
                    <TextField
                    label="label"
                    variant="outlined"
                    style={{width:"100%"}}
                    value = {props.level.label || ""}
                    onChange = {(e)=>{
                        let newLevel = {...props.level,label:e.target.value}
                        props.updateLevel({id:props.projectID,newLevel:newLevel})
                    }}
                    />
                </Grid>
                <Grid item style={{marginTop:"8px"}}>
                    <TextField
                    label="Button Text"
                    variant="outlined"
                    style={{width:"100%"}}
                    value = {props.level.buttonText || ""}
                    onChange = {(e)=>{
                        let newLevel = {...props.level,buttonText:e.target.value}
                        props.updateLevel({id:props.projectID,newLevel:newLevel})
                    }}
                    />
                </Grid>
                {
                    /*
 <Grid item style={{marginTop:"8px"}}>
                    <TextField
                    label="Cookie Expiration Days"
                    type ="number"
                    variant="outlined"
                    value = {parseInt(level.cookieTime) || 14}
                    style={{width:"100%"}}
                    onChange = {(e)=>{
                        let newLevel = {...level,cookieTime:parseInt(e.target.value)}
                        props.updateLevel({id:props.projectID,newLevel:newLevel})
                    }}
                    />
                </Grid>
                    */
                }
               
                <Grid item style={{backgroundColor:"#262626"}}>
                    <Paper style={{cursor:"pointer",backgroundColor:"#262626",margin:"8px",padding:"8px"}}
                    >
                        <Grid container direction = "column">
                            <Grid item>
                                <Typography
                                color = "secondary"
                                variant = "h6"
                                onClick={()=>{setHotspotsOpen(!hotspotsOpen)}}
                                >
                                    Hotspots
                                {hotspotsOpen === true
                                ?
                                <ArrowDropDownIcon style={{float:"right"}} color="secondary" fontSize="large"/>
                                :
                                <ArrowLeftIcon style={{float:"right"}} color="secondary" fontSize="large"/>
                                }
                                </Typography>
                                
                            </Grid>
                            {hotspotsOpen === true
                                ?
                                <Grid item style={{paddingBottom:"8px"}}>
                                    <HotspotPanels/>
                                </Grid>
                                :
                                <div style={{display:"none"}}/>
                            }
                        </Grid>
                       
                        
                    </Paper>
              </Grid>
                </div>
                : 
                <div/>}
                
            </Grid>
        </Paper>
    );
}

let mapStateToProps = state => ({
  projects : state.projects,
  pano_params: state.pano_params
})

let mapDispatchToProps = {
    updateLevel:updateLevel,
    setActiveLevel:setActiveLevel,
    updateProject:updateProject,
    deleteLevel:deleteLevel
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level);
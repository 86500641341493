import {SETCODEPOPOVERVISIBILITY} from './types';
export const setPopoverVisibility = (value) => dispatch => {
    dispatch(
        {
            type: SETCODEPOPOVERVISIBILITY,
            payload : value
        }
    );      
}


import React, { useState } from 'react';
import { connect } from 'react-redux';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {outputTheme} from '../../themes';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {setPopoverVisibility} from '../../actions/outputCodeActions'
import {Popover, ThemeProvider, Typography} from '@material-ui/core';

function OutputCode(props) {
    const [headCopied, setHeadCopied] = useState(false);
    const [bodyCopied, setBodyCopied] = useState(false);

    const addLevelString = (level) =>{
        return `wingame_360ty.addLevel("${level.id}","${level.link}","${level.label}","${level.buttonText}")`
    }
    const addHotspotString = (panel) =>{
        let panelImg = panel.div.querySelector("img")
        return `wingame_360ty.addWingameHotspot("${panel.imageSrc}",${panel.pan},${panel.tilt},"${panel.id}","${panel.node}",${panelImg.style.height.substring(0,panelImg.style.height.length-2)},${panelImg.style.width.substring(0,panelImg.style.width.length -2)},"${panel.level}")`
    }
    const addDateString = (project) =>{
    let string = 
    ``;
        if(project.useDate){
            if(project.startDate){
                let startDate = new Date(project.startDate)
    string = `${string} wingame_360ty.setStartDate(${startDate.getFullYear()},${startDate.getMonth()+1},${startDate.getDate()},${startDate.getHours()},${startDate.getMinutes()})
    `
            }
            if(project.endDate){
                let endDate = new Date(project.endDate)
    string = `${string} wingame_360ty.setEndDate(${endDate.getFullYear()},${endDate.getMonth()+1},${endDate.getDate()},${endDate.getHours()},${endDate.getMinutes()})
    `
            }
            return string;
        }else{
            return "";
        }
    }
    let project = props.projects.projects.filter(project => project.id === props.projects.activeProject);
    let levelsString = "";
    if(project[0] && project[0].levels && project[0].levels.length !== 0){
    project[0].levels.forEach((level)=>{
        levelsString = 
    `${levelsString}
    ${addLevelString(level)}`
    })
    }
    let hotspotsString = "";
    if(project[0] && props.hotspot_panels && props.hotspot_panels.panels && props.hotspot_panels.panels.length !== 0){
        props.hotspot_panels.panels.forEach((panel)=>{
            if(panel.project === project[0].id){
    hotspotsString = `${hotspotsString}
    ${addHotspotString(panel)}`
            }
        })
    }
    var headString = `<link rel="stylesheet" href="https://storage.googleapis.com/api.360ty.cloud/wingame/360ty_wingame_styles.css">`;
    
    let classElement = `<script src="https://storage.googleapis.com/api.360ty.cloud/wingame/360ty_class-wingame.js"></script>`
    let scriptOpen = 
    `<script>`;
    let scriptClose = 
    `</script>`;
    const tourBuilderEl = document.getElementById("tourbuilder_360ty_instance");
    const tourBuilderElDims = tourBuilderEl? tourBuilderEl.getBoundingClientRect() : {width: 1440, height: 960};
    let initWingameScript = 
`
${scriptOpen}
    var wingame_360ty = new Wingame_360ty(7,${tourBuilderElDims.height},${tourBuilderElDims.width});
    ${levelsString}
    ${hotspotsString}

    ${project[0] ? addDateString(project[0]) : ""}
    wingame_360ty.init()
${scriptClose}
`;
    var bodyString = 
    `${classElement}
    ${initWingameScript}
    `;
        return (
            <div style={{overflow:"hidden"}}>
            <ThemeProvider theme={outputTheme}>
                <Popover
                id={"outputCode"}
                open={props.isActive}
                anchorEl={document.body}
                onClose={ () => {
                    props.setPopoverVisibility(false)
                    setBodyCopied(false)
                    setHeadCopied(false)
                    }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                >
                    <Typography
                    variant="h4"
                    color="secondary"
                    align="center"
                    >
                        Head
                    </Typography>
                    <div  style={{whiteSpace:"pre-wrap"}}>
                    <CopyToClipboard 
                    text={headString}
                    onCopy={()=>{setHeadCopied(true); setTimeout(()=>{setHeadCopied(false)},1500)}}
                    >
                    {
                        headCopied === false?
                        <FileCopyOutlinedIcon
                        style={{position:"absolute",right:0,margin:"4px",cursor:"pointer",color:"white"}}
                         />
                         :
                         <div style={{position:"absolute",right:0,margin:"4px",cursor:"pointer"}}>
                            <CheckCircleOutlinedIcon
                            style={{color:"green",float:"right"}}
                            />
                        </div>
                        }
                        
                    </CopyToClipboard>
                        
                        <SyntaxHighlighter 
                        language="htmlbars"
                        style={a11yDark}
                       >
                           
{headString}
                        </SyntaxHighlighter>
                    
                    </div>
                    <Typography
                    variant="h4"
                    color="secondary"
                    align="center"
                    >
                        Body
                    </Typography>
                    <div  style={{whiteSpace:"pre-wrap"}}>
                    <CopyToClipboard 
                    text={bodyString}
                    onCopy={()=>{setBodyCopied(true); setTimeout(()=>{setBodyCopied(false)},1500)}}
                    >
                        {
                       bodyCopied === false?
                        <FileCopyOutlinedIcon
                        style={{position:"absolute",right:0,margin:"4px",cursor:"pointer",color:"white"}}
                         />
                         :
                        <div style={{position:"absolute",right:0,margin:"4px",cursor:"pointer"}}>
                            <CheckCircleOutlinedIcon
                            style={{color:"green",float:"right"}}
                            />
                        </div>
                        }
                    </CopyToClipboard>
                        <SyntaxHighlighter
                        language="htmlbars"
                        style={a11yDark}
                        >
{bodyString}
                        </SyntaxHighlighter>
                    </div>
                </Popover>
            </ThemeProvider>
          </div>
        );
}
let mapStateToProps = state => ({
    isActive : state.outputcode.open,
    projects : state.projects,
    hotspot_panels : state.hotspot_panels
  })
  
  let mapDispatchToProps = {
    setPopoverVisibility : setPopoverVisibility
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OutputCode);
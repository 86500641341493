import {combineReducers} from 'redux';
import panoReducer from './panoReducer';
import hotspotPanelsReducer from './hotspotPanelsReducer';
import imagePanelsReducer from './imagePanelsReducer';
import moveableHotspotReducer from "./moveableHotspotReducer";
import loginReducer from "./loginReducer";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import ProjectReducer from './ProjectReducer';
import OutputCodeReducer from './outputCodeReducer';

const rootReducer = combineReducers({
  pano_params : panoReducer,    
  firebase : firebaseReducer,
  firestore : firestoreReducer,
  hotspot_panels : hotspotPanelsReducer,
  image_panels : imagePanelsReducer,
  moveable_hotspot : moveableHotspotReducer,
  login : loginReducer,
  projects : ProjectReducer,
  outputcode : OutputCodeReducer
});

export default rootReducer;

        